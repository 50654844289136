import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";

const WaterBillPage = () => {
  const [waterBill, setWaterBill] = useState(null);
  const [userDetails, setUserDetails] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token && userDetails) {
      fetchWaterBill();
    }
  }, [token, userDetails]);

  const fetchWaterBill = async () => {
    try {
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/waterbill/getspecific/${userDetails}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setWaterBill(response.data.response);
    } catch (error) {
      console.error("Error fetching water bill:", error);
    }
  };

  const handleInputChange = (e) => {
    setUserDetails(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchWaterBill();
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Water Bill</h1>
        <div className="mb-4 flex items-center">
          {/* Input field for user details */}
          <input
            type="text"
            value={userDetails}
            onChange={handleInputChange}
            onKeyPress={handleEnterKeyPress}
            placeholder="Enter User Details and press Enter..."
            className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
          />
          {/* Button for fetching water bill */}
          <button
            onClick={fetchWaterBill}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Fetch Water Bill
          </button>
        </div>

        {waterBill ? (
          <div className="container mx-auto px-4 py-8">
            <div className="bg-white shadow-md rounded-md p-6">
              <p className="text-lg font-semibold mb-4">Water Bill Details</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="text-gray-700">
                    <strong>House Number:</strong> {waterBill.house_number}
                  </p>
                  <p className="text-gray-700">
                    <strong>Previous Units:</strong> {waterBill.previousunits}
                  </p>
                  <p className="text-gray-700">
                    <strong>Current Units:</strong> {waterBill.currentunits}
                  </p>
                </div>
                <div>
                  <p className="text-gray-700">
                    <strong>Amount:</strong> {waterBill.amount}
                  </p>
                  <p className="text-gray-700">
                    <strong>Creation Date:</strong> {waterBill.created_at}
                  </p>
                  <p className="text-gray-700">
                    <strong>Updated Date:</strong> {waterBill.updated_at}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p>No water bill available for this user.</p>
        )}
      </div>
    </AuthGuard>
  );
};

export default WaterBillPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const VacatingSttlement = () => {
  const [vacatingsettlei, setVacatingSettlement] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVacatingSettlements();
  }, []);

  const fetchVacatingSettlements = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        "https://backend.inyumba.co.ke/iNyumba/vacatinghouse/adminviewcompleteuserssettlement",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setVacatingSettlement(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching vacting settlements:", error);
        toast.error("Error fetching vacting settlements");
      });
  };

  const handleCompleteSettlement = (userIDset) => {
    const token = localStorage.getItem("token");
    axios
      .put(
        `https://backend.inyumba.co.ke/iNyumba/vacatinghouse/adminupdate/${userIDset}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        fetchVacatingSettlements();
        setLoading(true);
        toast.success(response.data.response);
      })
      .catch((error) => {
        console.error("Error updating Vacating Settlement:", error);
        toast.error("Error updating Vacating Settlement");
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">
          Tenants Complete Vacating
        </h1>

        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Deposit Settl Id</th>
              <th className="px-4 py-2">Property Id</th>
              <th className="px-4 py-2">User Id</th>
              <th className="px-4 py-2">Rental Id</th>
              <th className="px-4 py-2">Phone Number</th>
              <th className="px-4 py-2">Amount to Owner</th>
              <th className="px-4 py-2">Service Fee</th>
              <th className="px-4 py-2">Amount to User</th>
              <th className="px-4 py-2">Complete Status</th>
              <th className="px-4 py-2">Created On</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {vacatingsettlei.map((vacatingsettlei) => (
              <tr key={vacatingsettlei.depositsettlement_id}>
                <td className="border px-4 py-2">
                  {vacatingsettlei.depositsettlement_id}
                </td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.property_id}
                </td>
                <td className="border px-4 py-2">{vacatingsettlei.user_id}</td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.rental_id}
                </td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.phonenumber}
                </td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.amounttoowner}
                </td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.servicefee}
                </td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.amounttouser}
                </td>
                <td className="border px-4 py-2">
                  {vacatingsettlei.completestatus}
                </td>
                <td className="border px-4 py-2">
                  {new Date(vacatingsettlei.created_at).toLocaleString()}
                </td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() =>
                      handleCompleteSettlement(vacatingsettlei.user_id)
                    }
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2"
                  >
                    Complete Settlement
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AuthGuard>
  );
};

export default VacatingSttlement;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv } from "@fortawesome/free-solid-svg-icons";

const ReservationsPage = () => {
  const [reservations, setReservations] = useState([]);
  const [propertyId, setPropertyId] = useState("");
  const [webUrl, setWebUrl] = useState(""); // State for payment URL
  const [showIframe, setShowIframe] = useState(false); // State for iframe visibility
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (propertyId) {
      fetchReservations();
    }
  }, [propertyId]);

  const fetchReservations = () => {
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/user/reservations/viewAll/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setReservations(response.data.response);
        })
        .catch((error) => {
          console.error("Error fetching reservations:", error);
        });
    }
  };

  const handleInputChange = (e) => {
    setPropertyId(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchReservations();
    }
  };

  const handlePayment = (userId) => {
    const token = localStorage.getItem("token");

    axios
      .post(
        `https://backend.inyumba.co.ke/iNyumba/user/payment/initiate-payment/${userId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        toast.success("Payment initiated successfully");
        setWebUrl(response.data.response); // Set the payment URL from the response
        setShowIframe(true); // Show the iframe
      })
      .catch((error) => {
        console.error("Error initiating payment:", error);
        toast.error("Error initiating payment");
      });
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
  };

  const handleDownload = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reservationsreports`;

    // Fetch and download file
    axios
      .post(
        endpoint,
        { propertyId }, // Ensure propertyId is sent correctly
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { format: fileType }, // Send format as query parameter
          responseType: "blob", // Important for file download
        }
      )
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbareservations.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up
        toast.success("Download successful");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error("Error downloading");
      });
  };

  const handleDownloadAll = (fileType) => {
    const endpoint = `https://backend.inyumba.co.ke/iNyumba/reservationsreportsalltime`;

    // Fetch and download file
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `inyumbareservationrecords.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successfull");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error("Error downloading");
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Reservations</h1>

        <div className="flex space-x-2">
          <button
            onClick={() => handleDownloadAll("csv")}
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
          >
            <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
            Download All
          </button>
          <button
            onClick={() => handleDownloadAll("pdf")}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
          >
            <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
            Download All
          </button>
        </div>

        <div className="mb-4 flex justify-center">
          <input
            type="text"
            value={propertyId}
            onChange={handleInputChange}
            onKeyPress={handleEnterKeyPress}
            placeholder="Enter Property ID and press Enter..."
            className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
          />
          <button
            onClick={fetchReservations}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Fetch Reservations
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Download buttons for the property_id */}
          <div className="flex space-x-1 mb-2">
            <button
              onClick={() => handleDownload("csv")}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download Property
            </button>
            <button
              onClick={() => handleDownload("pdf")}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download Property
            </button>
          </div>
          {reservations.map((reservation) => (
            <div
              key={reservation.room_id}
              className="bg-white shadow-md rounded-md p-4"
            >
              <p>
                <strong>User Name:</strong> {reservation.user_name}
              </p>
              <p>
                <strong>Email:</strong> {reservation.email}
              </p>
              <p>
                <strong>Phone:</strong> {reservation.phone}
              </p>
              <p>
                <strong>Entry Date:</strong> {reservation.entrydate}
              </p>
              <p>
                <strong>House Number:</strong> {reservation.house_no}
              </p>
              <p>
                <strong>Booking Status:</strong> {reservation.booking_status}
              </p>
              <p>
                <strong>Deposit Paid:</strong> {reservation.deposit_paid}
              </p>
              <p>
                <strong>Payment Status:</strong> {reservation.payment_status}
              </p>
              {reservation.payment_status === "PENDING" && (
                <button
                  onClick={() => handlePayment(reservation.user_id)}
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
                >
                  Trigger Tenant Payment
                </button>
              )}
            </div>
          ))}
          {showIframe && webUrl && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
              <button
                onClick={handleCloseIframe}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
              >
                Close
              </button>
              <iframe
                src={webUrl}
                className="w-screen h-screen border-none mx-auto"
                title="Payment Iframe"
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </AuthGuard>
  );
};

export default ReservationsPage;

import React, { useState } from "react";
import axios from "axios";
import { AuthGuard } from "../App";

const OwnerBalancePage = () => {
  const [propertyId, setPropertyId] = useState("");
  const [ownerBalance, setOwnerBalance] = useState(null);

  const fetchOwnerBalance = () => {
    const token = localStorage.getItem("token");

    if (propertyId && token) {
      axios
        .get(
          `https://backend.inyumba.co.ke/iNyumba/balance/owner/${propertyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          setOwnerBalance(response.data.response[0]);
        })
        .catch((error) => {
          console.error("Error fetching owner balance:", error);
        });
    }
  };

  const handleInputChange = (e) => {
    setPropertyId(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchOwnerBalance();
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Owner Balance</h1>

        <div className="mb-4 flex justify-center">
          <input
            type="text"
            value={propertyId}
            onChange={handleInputChange}
            onKeyPress={handleEnterKeyPress}
            placeholder="Enter Property ID and press Enter..."
            className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
          />
          <button
            onClick={fetchOwnerBalance}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Fetch Owner Balance
          </button>
        </div>

        {ownerBalance !== null ? (
          <div className="bg-white shadow-md rounded-md p-6">
            <p className="text-lg font-semibold mb-4">Owner Balance Details</p>
            <p className="text-gray-700">
              <strong>Property ID:</strong> {ownerBalance.property_id}
            </p>
            <p className="text-gray-700">
              <strong>Balance:</strong> {ownerBalance.balance}
            </p>
          </div>
        ) : (
          <p>No owner balance available.</p>
        )}
      </div>
    </AuthGuard>
  );
};

export default OwnerBalancePage;

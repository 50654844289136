import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";

const BillsPage = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (userDetails) {
      fetchBills();
    }
  }, [userDetails]);

  const fetchBills = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/admin/bill/view-bill/${userDetails}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setBills(response.data.response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bills:", error);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setUserDetails(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchBills();
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-fulls">
          <div className="bg-white shadow-md rounded-md p-6 w-fulls">
            <div className="flex items-center justify-between mb-4 w-fulls">
              <h2 className="text-lg font-semibold">Bill Details</h2>
              <span className="text-1xl font-semibold mb-8">
                Date: {new Date().toLocaleDateString()}
              </span>
            </div>
            <div className="mb-4">
              {/* Input field for user details */}
              <input
                type="text"
                value={userDetails}
                onChange={handleInputChange}
                onKeyPress={handleEnterKeyPress}
                placeholder="Enter User Details and press Enter..."
                className="border border-gray-300 rounded-md py-2 px-4 w-full focus:outline-none focus:border-blue-500"
              />
            </div>
            {loading ? (
              <div className="bg-white shadow-md rounded-md p-4">
                <p className="text-lg font-semibold mb-4">Bills</p>
                <p className="text-gray-700 mb-2">Loading...</p>
                <div className="flex flex-col"></div>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-2">
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-600">
                      Water Bill:
                    </span>
                    <span className="text-lg font-bold text-blue-600">
                      Ksh {bills.WaterBill}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-600">
                      Rent Bill:
                    </span>
                    <span className="text-lg font-bold text-blue-600">
                      Ksh {bills.RentBill}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold text-gray-600">
                      Total Amount:
                    </span>
                    <span className="text-lg font-bold text-blue-600">
                      Ksh {bills.TotalAmount}
                    </span>
                  </div>
                  <div className="flex flex-col"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default BillsPage;

import React, { useState } from "react";
import axios from "axios";
import { AuthGuard } from "../App";

const UserBalancePage = () => {
  const [userData, setUserData] = useState("");
  const [userBalance, setUserBalance] = useState(null);

  const fetchUserBalance = () => {
    const token = localStorage.getItem("token");

    if (userData && token) {
      axios
        .get(`https://backend.inyumba.co.ke/iNyumba/balance/user/${userData}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUserBalance(response.data.response[0]);
        })
        .catch((error) => {
          console.error("Error fetching user balance:", error);
        });
    }
  };

  const handleInputChange = (e) => {
    setUserData(e.target.value);
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchUserBalance();
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">User Balance</h1>

        <div className="mb-4 flex justify-center">
          <input
            type="text"
            value={userData}
            onChange={handleInputChange}
            onKeyPress={handleEnterKeyPress}
            placeholder="Enter User Data and press Enter..."
            className="border border-gray-300 rounded-md py-2 px-4 w-64 focus:outline-none focus:border-blue-500 mr-2"
          />
          <button
            onClick={fetchUserBalance}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none"
          >
            Fetch User Balance
          </button>
        </div>

        {userBalance !== null ? (
          <div className="bg-white shadow-md rounded-md p-6">
            <p className="text-lg font-semibold mb-4">User Balance Details</p>
            <p className="text-gray-700">
              <strong>User ID:</strong> {userBalance.user_id}
            </p>
            <p className="text-gray-700">
              <strong>Balance:</strong> {userBalance.balance}
            </p>
          </div>
        ) : (
          <p>No user balance available.</p>
        )}
      </div>
    </AuthGuard>
  );
};

export default UserBalancePage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const VerifyCodes = () => {
  const [codes, setCodes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetcVerifyCodes();
  }, []);

  const fetcVerifyCodes = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/viewverifycodes",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCodes(response.data.response);
    } catch (error) {
      toast.error("Failed to fetch logs");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-6">Verify Codes</h1>

      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b">User ID</th>
            <th className="py-2 px-4 border-b">Code</th>
            <th className="py-2 px-4 border-b">Intent</th>
            <th className="py-2 px-4 border-b">Otp Resend Attempts</th>
            <th className="py-2 px-4 border-b">Email Verify Token</th>
            <th className="py-2 px-4 border-b">Email Expiry</th>
            <th className="py-2 px-4 border-b">OTP Expiry</th>
            <th className="py-2 px-4 border-b">Created At</th>
          </tr>
        </thead>
        <tbody>
          {codes.map((code) => (
            <tr key={code.index} className="border-b hover:bg-gray-100">
              <td className="py-2 px-4">{code.user_id}</td>
              <td className="py-2 px-4">{code.code}</td>
              <td className="py-2 px-4">{code.intent}</td>
              <td className="py-2 px-4">{code.otp_resend_attempts}</td>
              <td className="py-2 px-4">{code.email_verify_token}</td>
              <td className="py-2 px-4">{code.email_exp}</td>
              <td className="py-2 px-4">{code.otp_exp}</td>
              <td className="py-2 px-4">
                {new Date(code.created_at).toLocaleString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VerifyCodes;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { AuthGuard } from "../App";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv, faFilePdf } from "@fortawesome/free-solid-svg-icons";

const UsersPage = () => {
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [userData, setUserData] = useState("");
  const [originalUsers, setOriginalUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    const token = localStorage.getItem("token");
    axios
      .get("https://backend.inyumba.co.ke/iNyumba/users/allusers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsers(response.data.response);
        setOriginalUsers(response.data.response);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        toast.error("Error fetching users");
      });
  };

  const deleteUser = (id) => {
    const token = localStorage.getItem("token");
    axios
      .delete(`https://backend.inyumba.co.ke/iNyumba/users/${id}/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        toast.success("User deleted successfully");
        fetchUsers(); // Refetch users after deletion
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
        toast.error("Error deleting user");
      });
  };

  const searchUser = () => {
    if (!userData) return; // Don't search if no input
    const token = localStorage.getItem("token");
    axios
      .get(`https://backend.inyumba.co.ke/iNyumba/users/${userData}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setUsers([response.data.response]);
      })
      .catch((error) => {
        console.error("Error searching user:", error);
        toast.error("Error searching user");
      });
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    setUserData(searchInput); // Update userData state before searching
    searchUser(); // Then trigger search
  };

  const handleReset = () => {
    setUserData("");
    setSearchInput("");
    setUsers(originalUsers);
  };

  const handleDownload = (endpoint, fileType) => {
    const token = localStorage.getItem("token");
    axios
      .get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { format: fileType },
        responseType: "blob", // Important for file download
      })
      .then((response) => {
        const fileExtension = fileType === "pdf" ? "pdf" : "csv";
        const fileName = `usersreport_${new Date().toISOString()}.${fileExtension}`;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        toast.success("Download is successful");
      })
      .catch((error) => {
        console.error(`Error downloading ${fileType.toUpperCase()}:`, error);
        toast.error("Error downloading");
      });
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Users</h1>
        <div className="mb-4 flex">
          <input
            type="text"
            placeholder="Enter User Data"
            value={searchInput}
            onChange={handleSearchInputChange}
            className="border border-gray-300 rounded-md py-2 px-4 w-full"
          />
          <button
            onClick={handleSearch}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-2 rounded"
          >
            Search
          </button>
          <button
            onClick={handleReset}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 ml-2 rounded"
          >
            Reset
          </button>
        </div>
        {/* Download buttons */}
        <div className="flex justify-between w-full max-w-4xl mb-4 mt-4">
          <div className="flex space-x-2">
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/tenantsreports",
                  "csv"
                )
              }
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download Tenants CSV
            </button>
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/tenantsreports",
                  "pdf"
                )
              }
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download Tenants PDF
            </button>
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/landlordreports",
                  "csv"
                )
              }
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFileCsv} className="mr-1" />
              Download Landlords CSV
            </button>
            <button
              onClick={() =>
                handleDownload(
                  "https://backend.inyumba.co.ke/iNyumba/landlordreports",
                  "pdf"
                )
              }
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-md text-sm"
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download Landlords PDF
            </button>
          </div>
        </div>
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Id</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Uuid</th>
              <th className="px-4 py-2">Phone</th>
              <th className="px-4 py-2">Role</th>
              <th className="px-4 py-2">Status</th>
              <th className="px-4 py-2">Login Attempt</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="border px-4 py-2">{user.id}</td>
                <td className="border px-4 py-2">{user.name}</td>
                <td className="border px-4 py-2">{user.email}</td>
                <td className="border px-4 py-2">{user.uuid}</td>
                <td className="border px-4 py-2">{user.phone}</td>
                <td className="border px-4 py-2">{user.role}</td>
                <td className="border px-4 py-2">{user.status}</td>
                <td className="border px-4 py-2">{user.login_attempt}</td>
                <td className="border px-4 py-2">
                  <button
                    onClick={() => deleteUser(user.uuid)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </AuthGuard>
  );
};

export default UsersPage;

import React, { useState } from "react";
import axios from "axios";
import { AuthGuard } from "../App";

const MonthlyFeesPage = () => {
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [fees, setFees] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token"); // Retrieve bearer token from local storage
      const response = await axios.get(
        `https://backend.inyumba.co.ke/iNyumba/money/MonthlyFees?year=${year}&month=${month}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFees(response.data.response || 0);
    } catch (error) {
      console.error("Error fetching monthly fees:", error);
      setError("Error fetching monthly fees. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-6">Monthly Fees</h1>
        <form
          onSubmit={handleSubmit}
          className="mb-8 flex flex-col md:flex-row md:items-center"
        >
          <div className="flex-grow md:mr-4 mb-4 md:mb-0">
            <label htmlFor="yearInput" className="sr-only">
              Year:
            </label>
            <input
              type="number"
              id="yearInput"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              required
              className="border border-gray-300 rounded-md py-2 px-4 w-full"
              placeholder="Enter Year"
            />
          </div>
          <div className="flex-grow md:ml-4 mb-4 md:mb-0">
            <label htmlFor="monthInput" className="sr-only">
              Month:
            </label>
            <input
              type="number"
              id="monthInput"
              value={month}
              onChange={(e) => setMonth(e.target.value)}
              min="1"
              max="12"
              required
              className="border border-gray-300 rounded-md py-2 px-4 w-full"
              placeholder="Enter Month"
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex-shrink-0"
            disabled={loading}
          >
            {loading ? "Loading..." : "Submit"}
          </button>
        </form>

        {fees !== null && (
          <div className="flex flex-col items-center justify-center bg-white bg-opacity-25 rounded-lg p-6 shadow-lg">
            <p className="text-xl font-semibold mb-2">
              Total monthly fees for Year {year} - Month {month}:
            </p>
            <p className="text-2xl font-bold">Ksh {fees || 0}</p>
          </div>
        )}

        {error && <div className="text-red-500 mt-4">{error}</div>}
      </div>
    </AuthGuard>
  );
};

export default MonthlyFeesPage;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const AgencyViewingFee = () => {
  const [agencyFees, setAgencyFees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editFee, setEditFee] = useState(null);
  const [feeAmount, setFeeAmount] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    fetchAgencyFees();
  }, []);

  const fetchAgencyFees = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://backend.inyumba.co.ke/iNyumba/user/agencyviewingfee/viewagencyviewingfee",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAgencyFees(response.data.response);
    } catch (error) {
      toast.error("Failed to fetch agency fees");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (fee) => {
    setEditFee(fee.id);
    setFeeAmount(fee.fee_amount);
    setStatus(fee.status);
  };

  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        `https://backend.inyumba.co.ke/iNyumba/user/agencyviewingfee/updateagencyfee/${editFee}`,
        {
          fee_amount: feeAmount,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Agency viewing fee updated successfully");
      setEditFee(null);
      fetchAgencyFees();
    } catch (error) {
      toast.error("Failed to update agency fee");
    }
  };

  if (loading) {
    return <div className="text-center mt-6">Loading...</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-center mb-6">
        Agency Viewing Fees
      </h1>
      <table className="min-w-full bg-white border border-gray-200">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2 px-4 border-b">ID</th>
            <th className="py-2 px-4 border-b">UserId</th>
            <th className="py-2 px-4 border-b">Fee Amount</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {agencyFees.map((fee) => (
            <tr key={fee.id} className="border-b hover:bg-gray-100">
              <td className="py-2 px-4">{fee.id}</td>
              <td className="py-2 px-4">{fee.user_id}</td>
              <td className="py-2 px-4">{fee.fee_amount}</td>
              <td className="py-2 px-4">{fee.status}</td>
              <td className="py-2 px-4">
                <button
                  onClick={() => handleEdit(fee)}
                  className="bg-blue-500 text-white py-1 px-2 rounded hover:bg-blue-600 mr-2"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editFee && (
        <div className="mt-6 p-4 border border-gray-200 rounded">
          <h2 className="text-xl font-bold mb-4">Edit Agency Viewing Fee</h2>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Fee Amount</label>
            <input
              type="text"
              value={feeAmount}
              onChange={(e) => setFeeAmount(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">Status</label>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded"
            />
          </div>
          <button
            onClick={handleUpdate}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
          >
            Update
          </button>
        </div>
      )}
    </div>
  );
};

export default AgencyViewingFee;
